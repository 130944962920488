.Availability{
    font-family: 'Poppins';
    color: #3D3D3D;
    // White background fo mobile devices
    @media (max-width: 768px){
        background-color: #ffffff;
    }

    .m-reset-0{
        margin: 0;
    }
    .fl-col{
        display: flex;
        flex-direction: column;
    }
    .fl-row{
        display: flex;
        flex-direction: row;
    }
    .fl-rb{
        display: ruby;
    }
    .fl-sp-bw{
        justify-content: space-between;
    }
    .fl-item-cntr{
        align-items: center;
    }
    .txt-align-right{
        text-align: right;
    }
    .mr-10{
        margin-right: 10px;
    }
    .max-width-list{
        max-width: 85%;
    }
    .w-80{
        width: 80%;
    }
    .gap-30{
        gap: 30px;
    }
    .mr-30{
        margin-right: 30px;
    }
    .table-legend {
        margin-top: 12px;

        // Hide table legend on mobile screens
        @media (max-width: 768px){
            display: none;
        }
    }
    .timezone-text{
        color: #0D3270;
        font-weight: 500;
        font-size: 13px;
    }
    .heading-day{
        text-align: center;
        font-family: Poppins;
        color: #3D3D3D;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        text-transform: initial;
    }
    .heading-date{
        text-align: center;
        color: #3D3D3D;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.025em;
        text-transform: initial;
    }
    .headerComp{
        .heading{
            font-family: Poppins;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
        .sub-heading{
            font-family: Poppins;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
    }
    .p-btm{
        padding-bottom: 21px;
    }
    .pos-rel{
        position: relative;
    }
    // .date-pickeroWN{
    //     width: 149px;
    //     height: 30px;
    //     border: 1px solid #9A9A9A;
    //     box-sizing: border-box;
    //     border-radius: 4px;
    //     padding: 14px;
    // }
    // .dateIcon{
    //     position: absolute;
    //     top: 2px;
    //     right: 5px;
    // }
    .bttn{
        width: 131px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #123FBC;
        box-sizing: border-box;
        border-radius: 4px;
        color: #123FBC;
    }
    .btn-save{
        height: fit-content;
        background: #123FBC;
        border: 0;
        border-radius: 4px;
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 5px 16px;
        margin-bottom: 14px;
        display: flex;
        gap: 8px;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        // Button should not be visible on mobile devices
        @media (max-width: 768px){
            display: none;
        }
    }
    

    .sideBorder{
        height:30px;
        border-right: 2px solid #C9C9C9;

    }
    .slot-avail{
        display: inline-block;
        width: 36px;
        height: 12px;
        border: 3px solid #23376F;
        border-radius: 1px;
    }
    .slot-maxedOut{
      background-color: #D4D7DE;
      border: 3px solid #A2A6AF;
      display: inline-block;
      width: 36px;
      height: 12px;
      border-radius: 1px;
    }

    .slot-booked{
        display: inline-block;
        width: 36px;
        height: 12px;
        background: #23376F;
        border-radius: 1px;
    }
    .slot-empty{
        display: inline-block;
        width: 36px;
        height: 12px;
        background: #FFFFFF;
        border: 1px solid #D5E5FA;
        box-sizing: border-box;
        border-radius: 1px;
    }

    // Table FAB button - X Axis
    .table-fab-x {
        position: fixed;
        z-index: 9999;
        transform: translate(0, -50%);     
        bottom: 12vh;
        @media (min-width: 769px){
            display: none;
        }

        &.left {
            right: 12px;
        }

        &.right {
            left: 94px;
        }
    }

    // Table FAB button - Y Axis
    .table-fab-container {
        position: fixed;
        bottom: 16px;
        left: 50vw;
        transform: translate(-50%, 0); 
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 16px;
        z-index: 9999;

        @media (min-width: 769px){
            display: none;
        }
    }

    // Table styles
    .card.card-disabled{
        /*pointer-events: none;*/
        opacity: 0.8;
        cursor: not-allowed;
    }

    .card {
        margin-top: 16px;
        border-radius: unset;

        // Mobile screen requires the table to bleed against device screen's edge
        @media (max-width: 768px){
            width: 100vw;
            margin-left: -12px;
            margin-bottom: 5.5rem !important;
        }

        .table-body {
            white-space: nowrap;
            overflow-x: auto;

            .table {
                tbody {
                    .thead {
                        background-color: unset !important;

                        td {
                            border: 1px solid #D5E5FA;
                        }

                        td.timezone-in-table-th {
                            max-width: 85px;
                            position: sticky;
                            top: 0;
                            left: 0;
                            background-color: #ffffff;
                            z-index: 10;

                            .timezone-in-table{
                                font-weight: 400;
                                font-size: 12px;
                                font-family: Poppins;
                                color: #3D3D3D;
                                white-space: break-spaces;

                                @media (min-width: 768px){
                                    display: none;
                                }
                            }
                        }

                        .date-day-max-count-wrapper {
                            padding: 8px;
                            width: 56px;
                            position: sticky;
                            top: 0;

                            @media (min-width: 769px){
                                width: 120px;
                                padding: 12px;
                            }

                            @media (min-width: 1201px){
                                width: 136px;
                            }

                            .max-count-wrapper {
                                display: flex;
                                flex-wrap: nowrap;
                                gap: 8px;
                                align-items: center;
                                justify-content: center;
                                margin-top: 0;

                                .max-count-text {
                                    font-size: 11px;
                                    color: #3D3D3D;
                                    font-family: Poppins;
                                    text-transform: none;
                                }
                            }
                        }
                    }

                    // Availability rows
                    tr.availability-row {
                        height: 42px;

                        .availability-time {
                            font-weight: 600;
                            font-family: Poppins;
                            color: #3D3D3D;
                            text-align: center;
                            padding: 0 1rem !important;
                            background-color: #ffffff;
                            position: sticky;
                            max-width: 100px;
                            width: 100px;
                            left: 0;
                            z-index: 10;

                            @media (min-width: 769px){
                                padding: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
    // Table style ends

    .tittle{
        font-family: Poppins;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.025em;
        text-transform: capitalize;
        color: #3D3D3D;
    }

    .default-count-instruction-list-item {
        font-weight: 400;
        font-size: 12px;
        color: #3D3D3D;
        font-family: Poppins;
        line-height: 24px;
    }

    .default-count-helper-text {
        margin-top: 6px;
        margin-left: 0px;
        font-size: 12px;
        color: #3D3D3D;
        font-family: Poppins;
        line-height: 1.75;
    }

    .default-filter-text {
        margin-top: -2px;
        margin-left: 0px;
        font-size: 12px;
        font-family: 'Poppins';
        line-height: 1.75;
    }

    .max-count-label-highlighted {
        color: #123FBC;
        font-weight: 500;
    }
    
    .parent-max-count-wrapper {
        display: inline-block;
        margin-left: 8px;
        background-color: #fff;

        .parent-max-count {
            height: 22px;
            width: fit-content;
            box-shadow: 0px 2px 12px -2px #1E2F5F1A;
        }

        .MuiOutlinedInput-input {
            padding: 0px 32px 4px 14px;
        }
        .MuiInputBase-input {
            font-size: 12px;
            font-family: 'Poppins';
        }
        .MuiSvgIcon-root {
            top: calc(50% - 14px)
        }
    }

    .child-max-count-wrapper {
        display: inline-block;
        background-color: transparent;

        .child-max-count {
            color: #3D3D3D;
            font-size: 11px;
            font-weight: 500;
            font-family: Poppins;

            // Hides the underline from MUI's Select
            &::after, &::before {
                display: none;
            }

            // Arrow icon
            svg {
                top: calc(50% - 10px);
                right: 6px;
                left: 6px;
                fill: #3D3D3D;
                height: 18px;
            }
        }
    }

    .duration-wrapper {
        margin-left: 5px;

        .checkbox-container {
            margin-top: 4px;
            margin-right: 2px;
            padding: 0px;
        }

        .checkbox-text {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            margin-top: 5px;
        }
    }

    /* Cells that cannot be interacted with */
  .cell-disable {
    background: #23376F;
    border: 0px;
  }

  .circularprogress{
    margin: 0  0 0 15px;
  }

  .progress-text{
    color: #123fbc;
    font-weight: 500;
    margin: 0 0 0 15px;
    font-size: 12px;
    align-self: center;
  }
}